import React from "react"
import Layout from "../components/layout"
import EAHelmetSubcomp from "../components/EveryAction-helmet-subcomp"
import PrintButton from "../components/printbutton"

const RegistrationPage = () => {
  return (
    <Layout>
      <EAHelmetSubcomp
        title="CSICon: Register Today"
        description="Register for CSICon today!"
      />
      <h1 style={{ textAlign: "center", marginTop: "10px" }}>
        Register Now for the Premiere Skeptic Conference
        <hr />
      </h1>
      <div
        className="ngp-form"
        data-form-url="https://secure.everyaction.com/v1/Forms/ayV-bmHoM0WRWaRsBDalxQ2"
        data-custom-css="https://nvlupin.blob.core.windows.net/designs/CustomStylesheet_7e07e727d6bcb12caab1b0a2d750f45854d023241d316e4730abd9d702bfcb48.css"
        data-fastaction-endpoint="https://fastaction.ngpvan.com"
        data-inline-errors="true"
        data-fastaction-nologin="true"
        data-databag-endpoint="https://profile.ngpvan.com"
        data-databag="everybody"
        data-mobile-autofocus="false"
      ></div>
      <div class="row page-row">
        <div class="col-12 col-lg-8">
          <h1>LilliCon Information</h1>
          <p>
            We are excited to announce that the Lilienfeld Alliance will be
            hosting its first half-day academic conference this year! We are
            calling it LiliCon. It will be held in conjunction with CSICon,
            Thurs Oct 24, 2024, 1-5pm, at the Horseshoe Las Vegas Resort and
            Casino. We hope to promote the scientific study of our work, to
            discover what is effective, and to promote it. Furthermore, we hope
            to build the strength of connections among academics who are working
            toward this end by providing an inspirational meeting of the
            minds.  
          </p>
          <p>We are soliciting two types of proposals: </p>
          <p>
            1) 
            <span>Scholarship of Teaching and Learning</span>
             - research related to the teaching of rational skepticism in a
            higher education setting. Proposals must include data. 
          </p>
          <p>
            2) 
            <span>Teaching Demonstration</span>
             - description of a course focused on teaching rational skepticism,
            or an assignment, or an in-class activity. These must include some
            kind of audience-involved demonstration. 
          </p>
          <p>We will have both presentations and posters. </p>
          <p>
            We hope that each of you will consider sharing your work, and help
            us solicit proposals from other scholars. A PDF of the call for
            proposals is attached. Feel free to share among your contacts and
            encourage students to submit as well. 
          </p>
          <a href="https://forms.gle/KwUzaYVm9Bo4ERXC8 ">
            Submit proposals by June 1
          </a>
        </div>
      </div>

      <PrintButton />
    </Layout>
  )
}

export default RegistrationPage
